import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface IGroupProgressJSON extends IStepSyncProgressJSON {
    stepsProgress: Array<IStepSyncProgressJSON>
}

export interface IGroupProgressOptions extends IStepSyncProgressOptions {
    stepsProgress: Array<StepSyncProgress>;
}

export class GroupProgress extends StepSyncProgress {
    public stepsProgress: Array<StepSyncProgress>

    constructor(options: IGroupProgressOptions) {
        super(options);

        this.stepsProgress = options.stepsProgress;
    }

    public toJSON(): IGroupProgressJSON {
        return Object.assign(super.toJSON(), this, {
            stepsProgress: this.stepsProgress.map((stepProgress) => stepProgress.toJSON())
        });
    }
}
